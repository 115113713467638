<template>
  <el-dialog
      v-bind="$attrs"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <el-form label-width="110px" label-position="left">
      <el-form-item label="关键字">
        <div style="display: flex">
          <el-input v-model="searchObj[`${searchObj.searchWay}`]" clearable>
            <el-select style="width: 100px" v-model="searchObj.searchWay" slot="prepend" placeholder="请选择">
              <el-option v-for="(item,index) in searchParams" :label="item" :value="index"></el-option>
            </el-select>
          </el-input>
          <el-button style="margin-left: 10px" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-table style="margin-top: 10px" border :data="list">
      <el-table-column label="订单号" >
        <template slot-scope="scope">
          <span style="display: block">{{scope.row.goods_list_str}}</span>
          <el-tooltip class="item" style="display: block" effect="dark" content="点击查看详情" placement="right">
            <el-button type="text" @click="toDetail(scope.row.id)">{{scope.row.order_sn}}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="收件人">
        <template slot-scope="scope">
          <span style="display: block">{{scope.row.address_info.real_name}}</span>
          <span style="display: block">{{scope.row.address_info.country}}{{scope.row.address_info.province}}{{scope.row.address_info.city}}{{scope.row.address_info.area}}{{scope.row.address_info.street}}</span>
          <span style="display: block">{{scope.row.address_info.real_phone}}</span>
          <span style="display: block">{{scope.row.user_info.email}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="order_state_text" width="120"></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="small" @click="select(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetOrderChooseList} from "@/request/api";

export default {
  inheritAttrs: false,
  name: "selectOrder",
  data(){
    return {
      list: [],
      searchParams:{
        "goods_name": "商品名",
        "order_sn": "订单号",
        "realphone": "收货人手机",
        "realname": "收货人姓名",
        "street": "收货街道",
        "sku": "具体sku",
        "remark": "备注",
      },
      searchObj:{
        searchWay: "goods_name",
      },
    }
  },
  methods:{

    onOpen(){},
    onClose() {
    },
    close(e) {
      this.$emit('update:visible', false)
    },
    select(row) {
      this.$emit('selectHandle', row)
      this.close()
    },
    toDetail(id) {
      this.$router.push({
        path:'/order/orderDetail',
        query:{
          id:id
        }
      })
    },
    search() {
      var data = {}
      if(this.searchObj.searchWay){
        data[`${this.searchObj.searchWay}`] = this.searchObj[`${this.searchObj.searchWay}`];
      }
      apiGetOrderChooseList(data).then(res=>{
        if(res.code == 200){
          this.list = res.data.list
        }
      })
    }
  }
}
</script>

<style scoped>

</style>