<template>
  <div class="page">
    <el-row :gutter="20">
      <el-col :span="12">
    <el-form label-width="120px" label-position="left">
      <h2>选择订单</h2>
      <el-form-item label="订单">
        <div style="display: flex;">
          <el-input v-model="orderObj.order_sn" readonly></el-input>
          <el-button v-if="kind == 'create'" style="margin-left: 10px;" type="primary" @click="showSelectOrder = true">选择订单</el-button>
        </div>
      </el-form-item>
      <el-form-item label="订单概述">
        <el-input type="textarea" :rows="5" v-model="orderDetail" readonly></el-input>
      </el-form-item>
      <h2>售后信息</h2>
      <el-form-item label="售后类型">
        <el-radio-group v-model="afterSaleObj.kind">
          <el-radio-button :label="0">仅退款</el-radio-button>
          <el-radio-button :label="1">退货退款</el-radio-button>
          <el-radio-button :label="2">仅换货</el-radio-button>
          <el-radio-button :label="3">普通售后</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="售后原因">
        <el-select placeholder="请选择" v-model="afterSaleObj.reason">
          <el-option v-for="item in reasonList"  :label="item.reason" :value="item.reason"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="afterSaleObj.kind == 1 || afterSaleObj.kind == 2" label="退款方式">
        <el-radio-group v-model="afterSaleObj.refund_type">
          <el-radio-button :label="0">原路返回</el-radio-button>
          <el-radio-button :label="1">账户余额</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="afterSaleObj.kind == 3 || afterSaleObj.kind == 2" label="退货地址">
        <el-select placeholder="请选择" v-model="afterSaleObj.return_addr_id">
          <el-option v-for="item in addressList"  :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户备注">
        <el-input type="textarea" :rows="5" v-model="afterSaleObj.descr"></el-input>
      </el-form-item>
      <el-form-item label="后台备注">
        <el-input type="textarea" :rows="5" v-model="afterSaleObj.remark"></el-input>
      </el-form-item>
    </el-form>
        <div class="fucView">
          <el-button type="primary" @click="submit">提交</el-button>
          <el-button  @click="init">重置</el-button>
        </div>
      </el-col>
    </el-row>
  <selectOrder :visible.sync="showSelectOrder" title="选择订单" @selectHandle="selectHandle"/>
  </div>
</template>

<script>
import selectOrder  from "@/views/order/components/selectOrder";
import {apiAddSupport, apiGetAddressList, apiGetReturnReasonTypeList} from "@/request/api";
import {mapActions,mapMutations } from "vuex";
export default {
  name: "afterSale",
  components: {
    selectOrder
  },
  data() {
    return {
      kind:this.$route.query.kind,
      id:this.$route.query.id,
      showSelectOrder:false,
      orderObj:{},
      orderDetail:'',
      afterSaleObj:{},
      reasonList:[],
      addressList:[],
    }
  },
  created() {
    this.init();
    this.getReasonList();
    console.log(this.$route)
    console.log(this.$router)
  },
  methods:{
    ...mapActions({
      delVisitedRoute: 'tabs/delVisitedRoute',
    }),
    ...mapMutations({
      reset: 'setRefresh'
    }),
    getReasonList(){
      apiGetReturnReasonTypeList().then(res=>{
        if(res.code == 200){

          this.reasonList = res.data;
        }

      })
    },
    init(){
      this.afterSaleObj={
        order_id:'',
            user_id:'',
            is_receive: 0,
            kind:1,
            descr:'',
            remark:'',
            refund_type:0,
            return_addr_id:''
      }
    },
    getAddressList(id){
      apiGetAddressList({user_id:id}).then(res => {
        if(res.code == 200){
          var list = res.data;
          list.forEach(item => {
            item.label = `${item.real_name}-${item.real_phone}-${item.country}${item.province}${item.city}${item.area}${item.street}`;
          });
          this.addressList = list;
        }
      })
    },
    selectHandle(data){
      this.orderObj = data;
      this.orderDetail = `${data.goods_list_str}\n${data.address_info.real_name}\n${data.address_info.real_phone}\n${data.address_info.email}\n${data.address_info.country}${data.address_info.province}${data.address_info.city}${data.address_info.area}${data.address_info.street}`;
      this.afterSaleObj.order_id = data.id;
      this.afterSaleObj.user_id = data.user_info.id;
      this.getAddressList(data.user_info.id)
    },
    submit(){
      apiAddSupport(this.afterSaleObj).then(res => {
        if(res.code == 200){
          this.$message.success('提交成功');
          this.delVisitedRoute(this.$route.path)
          this.$store.commit('removeKeepPages', 'afterSale')
          this.reset(true);
          this.$router.push({name:'afterSaleList'})
        }else{
          this.$message({
            message: res.message,
            type: 'error'
          });
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.el-form-item{
  margin-bottom: 10px;
}
.fucView {
  display: flex;
  justify-content: flex-end;
}
</style>
