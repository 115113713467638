import { render, staticRenderFns } from "./selectOrder.vue?vue&type=template&id=a546defe&scoped=true&"
import script from "./selectOrder.vue?vue&type=script&lang=js&"
export * from "./selectOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a546defe",
  null
  
)

export default component.exports